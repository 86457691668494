import { registerLocaleData } from '@angular/common';
import { DatePipe } from '@angular/common';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import localeDECH from '@angular/common/locales/de-CH';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { SecurityModule } from './security/security.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { MainModule } from './main/main.module';
import { AssignmentModule } from './assignments/assignment.module';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

registerLocaleData(localeDECH);

const defaultModules = [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
];

const internalModules = [
    AppRoutingModule,
    SharedModule,
    MainModule,
    SecurityModule
];

const externalModules = [TranslocoRootModule];

@NgModule({
    declarations: [AppComponent],
    imports: [
        defaultModules,
        internalModules,
        externalModules,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        DatePipe,
        { provide: LOCALE_ID, useValue: 'de-CH' },
        // Scope cannot be null if defined => otherwise the translation will not work
        { provide: TRANSLOCO_SCOPE, useValue: ['pmtBootstrap', 'pmtDatepicker'] },


    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
