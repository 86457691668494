import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { UserAuthorizationService } from './security/user-authorization.service';
import { HeaderStateService } from './main/headers/services/header-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'web-staff-app';

  constructor(
    // private userAuthorizationService: UserAuthorizationService,
    public headerStateService: HeaderStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {

  }
}
